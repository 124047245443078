import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import { useReward } from 'react-rewards';
import { Box, TextField, Button } from "@mui/material";
import { alpha, styled } from '@mui/material/styles';
import shuffle from "lodash/shuffle";

import ModalReward from "components/modalReward";
import { getSetting, drawReward } from "services/homepage.service";
import { alertError } from "services/alert.service";
import { getBackendPath } from "utils/common";

import Chest from "assets/chest.png";
import ChestOpen from "assets/chestOpen.png";
import AudioAnimate from "assets/audio/animate.wav";
import AudioReward from "assets/audio/gotreward.mp3";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

import { 
  StyledPage, 
  GridFlipMove, 
  StyledReward, 
  AudioWrapper,
  TadaDiv,
  WarningMessage,
  StyledButton
} from "./styled";

const CustomTextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function Homepage() {
  const { isSuccess, data } = useQuery(["setting"], getSetting);

  const [setting, setSetting] = useState(null);
  const [fortunePrices, setFortunePrices] = useState([]);
  const [showReward, setShowReward] = useState(false);
  const [selectedReward, setSelectedReward] = useState(null);
  const [ticketNo, setTicketNo] = useState("");
  const [openReward, setOpenReward] = useState(false);
  const [isPlay, setIsPlay] = useState(false);
  const [onSubmit, setOnSubmit] = useState(false);
  const [pickRewardIdx, setPickRewardIdx] = useState(null);

  const audioReward = new Audio(AudioReward);
  audioReward.volume = 0.8;

  const { reward: rewardFirework } = useReward("fortuneReward", "confetti", {
    zIndex: 100,
    spread: 45
  });

  useEffect(() => {
    if (isSuccess && data) {
      setFortunePrices(data?.fortunePrices ?? []);
      setSetting(data);
    }
  }, [isSuccess, data]);


  const mutationDrawReward = useMutation(values => drawReward({values}));

  const animateTicket = () => {
    setShowReward(false);
    if (ticketNo === "") {
      alertError("Silahkan masukan no tiket");
    } else {
      setOnSubmit(true);

      const animateInterval = setInterval(() => {
        setFortunePrices(shuffle(fortunePrices));
      }, 350);
  
      setTimeout(() => {
        clearInterval(animateInterval);
      }, 4000);
    }
  }

  const handleUseTicket = () => {
    setOnSubmit(true);
    setShowReward(false);
    setSelectedReward(null);
    // setAnimateIdx(null);

    mutationDrawReward.mutate({
      ticketNo: ticketNo ? ticketNo : "",
    }, {
      onSuccess: (res) => {
        if (res) {
          setSelectedReward(res);
          rewardFirework();
          setOpenReward(true);
          setOnSubmit(false);
          audioReward.play();
        } else {
          setOnSubmit(false);
        }
      }
    });
  }

  const renderLuckyBag = (isOpen, isAnimate, isNormal, reward, index) => {
    if (isNormal) {
      return (
        <img 
          src={ChestOpen} 
          className={onSubmit ? "pick-reward" : ""}
          alt="fortune-price" 
          onClick={() => {
            if (onSubmit === true) {
              setPickRewardIdx(index);
              handleUseTicket();
            }
          }} 
        />
      )
    }

    if (isAnimate) {
      return (
        <TadaDiv>
          <img src={ChestOpen} alt="fortune-price" />
        </TadaDiv>
      )
    }

    if (isOpen) {
      return (
        <React.Fragment>
          <img src={ChestOpen} className="img-selected" alt="fortune-price" />
          {
            pickRewardIdx === null && (
              <div className="hover-reward">
                <span>{reward}</span>
              </div>
            )
          }
        </React.Fragment>
      )
    }

    return <img src={ChestOpen} alt="fortune-price" />
  }

  const playBgAudio = () => {
    if (setting?.enableAudio === true) {
      const audioPlayer = document.getElementById("AudioPlayer");
      if (audioPlayer.paused) {
        audioPlayer.muted = false;
        audioPlayer.volume = 0.3;
        audioPlayer.play();
        setIsPlay(true);
      }
    }
  }

  return (
    setting ? (
      <React.Fragment>
        <StyledPage id="Homepage" title="Home" onClick={() => playBgAudio()}>
          <div>
            <StyledButton>
              {
                (setting?.daftarLink && setting?.daftarLink !== "") && (
                  <Button className="show-btn" variant="contained" onClick={() => {
                    window.open(setting.daftarLink, "_blank")
                  }}>
                    Daftar
                  </Button>
                )
              }
              {
                (setting?.loginLink && setting?.loginLink !== "") && (
                  <Button className="show-btn" variant="contained" onClick={() => {
                    window.open(setting.loginLink, "_blank")
                  }}>
                    Login
                  </Button>
                )
              }
            </StyledButton>
            <div className="content-wrapper">
              <div className="logo">
                <img src={getBackendPath(setting.bgLogoBase64)} alt="logo" />
              </div>
              <div className="fortune-wrapper">
                <Box sx={{ flexGrow: 1 }}>
                  <StyledReward id="fortuneReward"></StyledReward>
                  <GridFlipMove
                    staggerDurationBy="30"
                    duration={350}
                    enterAnimation={"accordionHorizontal"}
                    leaveAnimation={"accordionHorizontal"}
                  >
                    {
                      fortunePrices?.map((fortunePrice, index) => (
                        <div key={fortunePrice.reward}>
                          <div className="image-wrapper">
                            {/* {
                              showReward ? (
                                renderLuckyBag(true, false, false, fortunePrice.reward)
                              ) : (
                                selectedReward?.rewardIdx === index ? (
                                  renderLuckyBag(true, false, false, fortunePrice.reward)
                                ) : (
                                  animateIdx === index ? (
                                    renderLuckyBag(false, true, false, fortunePrice.reward)
                                  ) : (
                                    renderLuckyBag(false, false, true, fortunePrice.reward)
                                  )
                                )
                              )
                            } */}
                            {
                              showReward ? (
                                renderLuckyBag(true, false, false, fortunePrice.reward, index)
                              ) : (
                                pickRewardIdx === index ? (
                                  renderLuckyBag(true, false, false, fortunePrice.reward, index)
                                ) : (
                                  renderLuckyBag(false, false, true, fortunePrice.reward, index)
                                )
                              )
                            }
                          </div>
                        </div>
                      ))
                    }
                  </GridFlipMove>
                </Box>
              </div>
              {
                !onSubmit ? (
                  <div className="input-wrapper">
                    <CustomTextField
                      label="No Tiket"
                      id="ticket-input"
                      variant="filled"
                      autoComplete="off"
                      disabled={onSubmit}
                      onChange={(e) => setTicketNo(e.target.value)}
                    />
                    <Button disabled={onSubmit} variant="contained" onClick={() => {
                      animateTicket();
                      // handleUseTicket();
                    }}>Pakai Tiket</Button>
                    <Button disabled={onSubmit} className="show-btn" variant="contained" onClick={() => setShowReward(true)}>Tunjukkan Hadiah</Button>
                  </div>
                ) : (
                  <WarningMessage>
                    <NewReleasesIcon />
                    Silakan Pilih Kotak Misteri
                  </WarningMessage>
                )
              }
            </div>
          </div>
        </StyledPage>

        <ModalReward 
          open={openReward}
          setOpen={setOpenReward}
          reward={selectedReward?.reward}
          setSelectedReward={setSelectedReward}
          setPickRewardIdx={setPickRewardIdx}
        />
        
        <AudioWrapper>
          <audio id="AudioPlayer" 
            src={getBackendPath(setting.audio)} 
            loop={true} 
            autoPlay={true} 
            muted={true} 
            style={{display: "none"}} 
          />
          {
            isPlay ? (
              <VolumeOffIcon 
                onClick={() => {
                  setIsPlay(!isPlay);
                  document.getElementById("AudioPlayer").muted = true;
                }} 
              />
            ) : (
              <VolumeUpIcon 
                onClick={() => {
                  setIsPlay(!isPlay);
                  document.getElementById("AudioPlayer").muted = false;
                  playBgAudio();
                }} 
              />
            )
          }
        </AudioWrapper>
      </React.Fragment>
    ) : (
      ""
    )
  );
}
